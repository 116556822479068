.parentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 12% 0px;
}
.childContainer{
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  justify-content: center;
  align-items: stretch;
}
.leftContainer {
  flex-basis: 100%;
  height: 100vw;
  display: flex;
  align-items: center;
}
.imageContainer {
  position: relative;
  width: 80%;
}
.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: white;
}
.pinkShade {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 20%;
  right: -20%;
  background-image: linear-gradient(
    var(--gradient-red),
    var(--gradient-dark-red)
  );
}
.rightContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.descriptorAlignment {
  text-align: center;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .parentContainer{
    padding: 20px 0px;
  }
  .leftContainer {
    order: 2;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
  }
  .rightContainer {
    order: 1;
    flex: 1;
  }
  .imageContainer{
    top:-25px;
  }
  .descriptorAlignment {
    text-align: right;
    width: 85%;
    justify-content: flex-end;
  }
}
@media screen and (min-width:1024px){
  .descriptorAlignment{
    width: 70%;
  }
}
@media screen and (min-width:1366px){
  .parentContainer{
    min-height: 600px;
  }
}
@media screen and (min-width:2560px){
  .descriptorAlignment{
    width: 65%;
  }
}